import { SelectionModel } from '@angular/cdk/collections';
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  EventService,
  ScrollService,
} from '@jfw-library/ecommerce/core';
import { ValidationService } from '@jfw-library/ecommerce/shared';
import {
  eventMemberMeasurementsComplete,
  getIndividualView,
  getMemberView,
  isUserOrganizerOrAdminForEvent,
  setLastVisitedPage,
} from 'business-logic';
import { Event, EventMember, Look } from 'common-types';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';
import { ShippingDetailsModalComponent } from '../modals/shipping-details/shipping-details.component';

@Component({
  selector: 'app-track-progress',
  templateUrl: './track-progress.component.html',
  styleUrls: [
    './track-progress.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class TrackProgressComponent implements OnInit, OnDestroy {
  @ViewChild('manageSection') private manageSection!: ElementRef;

  event!: Event;
  subscription = new Subscription();
  isAdminOrOrganizer: boolean = false;
  isIndividual: boolean = false;
  individualLook!: Look;
  isMemberView: boolean = false;
  membersAvailable: boolean = true;

  altNow: any;
  eventIsNearMonthsAccepted: number = 2;
  eventIsNearMonthsMeasurements: number = 1;
  eventIsNearMonthsPaid: number = 1;
  eventIsNearAccepted: boolean = false;
  eventIsNearMeasurements: boolean = false;
  eventIsNearPaid: boolean = false;
  selection = new SelectionModel<EventMember>(true, []);
  isEventDateLocked = true;
  isEventDateLockedMsg = '';
  expandedMobileMembersPanel: Array<boolean> = [];

  tableColumns: string[] = [
    'memberName',
    'inviteSent',
    'inviteAccepted',
    'measurements',
    'paid',
    'shipped',
    'email',
  ];

  memberViewColumns: number[] = [0, 3, 4, 5];
  singleUserViewColumns: number[] = [0, 3, 4, 5];

  showScrollToTop = false;

  currentUserId: string | undefined;

  invitesResent: boolean = false;
  errorSending: boolean = false;
  sendingInvites: boolean = false;
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    private eventService: EventService,
    private eventManagerService: EventManagerService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private validationService: ValidationService,
    private authService: AuthService,
    private router: Router,
    private scrollService: ScrollService,
  ) {
    this.currentUserId = this.authService.getCurrentUser()?.uid;
  }

  ngOnInit(): void {
    /// TODO: Could this be related to the issue Erin experienced.
    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          console.log("Event received in TrackProgressComponent")
          this.event = event;
          this.initAfterEventReceived(event);
        },
      }),
    );

    this.subscription.add(
      this.route.queryParams.subscribe({
        next: (params) => {
          if (params.altNow !== undefined) {
            this.altNow = params.altNow;
            // this.eventIsNearMonthsAccepted = Number(params.cutoff);
            // this.eventIsNearMonthsAccepted = Number(params.cutoff);
            // this.eventIsNearMonthsAccepted = Number(params.cutoff);
          }
        },
      }),
    );

    // this.isIndividual = getIndividualView(this.event);
    // this.setIsAdminOrOrganizer();
    // this.filterColumnsForMember();
    // this.setIndividualLook();
    // this.removeInvalidMembers();
    // this.isMemberView = getMemberView(this.event);
    // this.setEventIsNear();
    // this.checkEventDateLocked();

    // if (history.state.orderConfirmation) {
    //   /// Wait a few seconds, and get the latest Event changes in case there is a delay in the Cart Submission process.
    //   this.delayedEventUpdate(3000);

    //   /// Wait a few seconds, and get the latest Event changes in case there is a delay in the Cart Submission process.
    //   this.delayedEventUpdate(10000);
    // }

    setLastVisitedPage('/track-progress');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initAfterEventReceived(event: Event) {

    this.isIndividual = getIndividualView(event);
    this.setIsAdminOrOrganizer(event);
    this.filterColumnsForMember(event);
    this.setIndividualLook(event);
    this.removeInvalidMembers(event);
    this.isMemberView = getMemberView(event);
    this.setEventIsNear(event);
    this.checkEventDateLocked(event);

  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const manageSectionHeight = this.manageSection.nativeElement.offsetHeight;
    if (
      manageSectionHeight !== 0 &&
      window.pageYOffset > 5 * manageSectionHeight
    ) {
      this.showScrollToTop = true;
    } else {
      this.showScrollToTop = false;
    }
  }

  /*
    // No longer need with Event Sync
    delayedEventUpdate(delay: number) {
      setTimeout(() => {
        this.getLatestEventUpdates();
      }, delay);
    }

    async getLatestEventUpdates() {
      this.event = await this.eventService.setSelectedEvent(this.event.id, 'TrackProgressComponent -- getLatestEventUpdates()');
      // this.event = await this.eventService.getSelectedEvent(true);

      // this.eventService.selectedEvent$.next(this.event);
    }
   */
  scrollToTop(): void {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    this.scrollService.scrollToTop();
  }

  checkEventDateLocked(event: Event): void {
    const eventDateValidator = this.validationService.isEventDateLocked(
      event,
      'track-progress',
    );
    if (!eventDateValidator.valid) {
      this.isEventDateLocked = true;
      this.isEventDateLockedMsg = eventDateValidator.msg;
    } else {
      this.isEventDateLocked = false;
    }
  }

  routeToMeasurements() {
    this.router.navigate(['/event', this.event.id, 'review-measurements']);
  }

  removeInvalidMembers(event: Event) {
    if (this.isAdminOrOrganizer) {
      return;
    }

    let eventMembers: EventMember[] = [];

    console.log('Me?');

    console.log(this.currentUserId);

    event.members.forEach((member) => {
      // if (member.memberProgress?.paidBy === this.currentUserId) {
      eventMembers?.push(member);
      // this.event.members = this.event.members.filter(
      //   (eventMember) => eventMember.id !== member.id
      // );
      // eventMembers = this.event.members.filter(
      //   (eventMember) => eventMember.id !== member.id
      // );
      // }
    });

    console.log('Members:');
    console.log(eventMembers);

    this.event.members = eventMembers;

    if (this.event.members.length <= 0) {
      this.membersAvailable = false;
    }
  }

  filterColumnsForMember(event: Event) {
    const isIndividual = event.isSingleUser;
    const filteredColumnsByRole: string[] = [];
    if (!this.isAdminOrOrganizer && !isIndividual) {
      this.memberViewColumns.forEach((colIndex) => {
        filteredColumnsByRole.push(this.tableColumns[colIndex]);
      });
      this.tableColumns = filteredColumnsByRole;
    } else if (isIndividual) {
      this.singleUserViewColumns.forEach((colIndex) => {
        filteredColumnsByRole.push(this.tableColumns[colIndex]);
      });
      this.tableColumns = filteredColumnsByRole;
    }
  }

  setIsAdminOrOrganizer(event: Event) {
    this.isAdminOrOrganizer = isUserOrganizerOrAdminForEvent(event);
  }

  setIndividualLook(event: Event): void {
    if (this.isIndividual) {
      const member = event.members[0];
      if (member.memberLook) {
        this.individualLook = member.memberLook;
      }
    }
  }

  async sendSelectedInvites() {
    this.errorSending = false;
    this.sendingInvites = true;
    this.invitesResent = false;
    const results = await Promise.allSettled(
      this.selection.selected.map(async (member, index, array) => {
        try {
          if (!member?.email) {
            throw new Error(
              'Member email is undefined.  Unable to send invite.',
            );
          }
          const result = await this.eventManagerService.sendMemberInvite(
            this.event,
            member,
          );
          return result;
        } catch (error) {
          console.error('Failed to send invite email', error);
          throw error;
        }
      }),
    );

    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        this.selection.deselect(this.selection.selected[index]);
      } else {
        this.errorSending = true;
      }
    });

    this.invitesResent = true;
    this.sendingInvites = false;
  }

  selectInviteToggle($event: any, selection: any, member: EventMember) {
    this.invitesResent = false;
    if (!this.sendingInvites) {
      this.errorSending = false;
      selection.toggle(member);
    }
  }

  setEventIsNear(event: Event) {
    //const currentDate = dayjs(Date.now()).add(this.cutOffModifier, 'month');
    let currentDate = dayjs(Date.now());
    let originalCurrentDate = currentDate;

    if (this.altNow !== undefined) {
      let date = new Date(
        this.altNow.slice(4, 8),
        this.altNow.slice(0, 2) - 1,
        this.altNow.slice(2, 4),
      );
      currentDate = dayjs(date);
    }
    const eventDate = event.eventDate * 1000;
    const cutOffDateAccepted = dayjs(eventDate).subtract(
      this.eventIsNearMonthsAccepted,
      'month',
    );
    const cutOffDateMeasurements = dayjs(eventDate).subtract(
      this.eventIsNearMonthsMeasurements,
      'month',
    );
    const cutOffDatePaid = dayjs(eventDate).subtract(
      this.eventIsNearMonthsPaid,
      'month',
    );
    console.log('originalCurrentDate:' + originalCurrentDate);
    console.log('altDate:' + this.altNow);
    console.log('currentDate:' + currentDate);
    console.log('cutOffDateAccepted:' + cutOffDateAccepted);
    console.log('cutOffDateMeasurements:' + cutOffDateMeasurements);
    console.log('cutOffDatePaid:' + cutOffDatePaid);

    if (currentDate >= cutOffDateAccepted) {
      this.eventIsNearAccepted = true;
    }

    if (currentDate >= cutOffDateMeasurements) {
      this.eventIsNearMeasurements = true;
    }
    if (currentDate >= cutOffDatePaid) {
      this.eventIsNearPaid = true;
    }
  }

  scrollToView() {
    if (!this.isBrowser) return;
    const yOffset = -100;
    const y =
      this.manageSection.nativeElement.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    // window.scrollTo({ top: y, behavior: 'smooth' });
    this.scrollService.scrollToPosition([0, y]);
  }

  measurementsCompleted(member: EventMember): boolean {
    return eventMemberMeasurementsComplete(member);
  }

  openShippingModal(member: EventMember) {
    this.dialog.open(ShippingDetailsModalComponent, {
      minWidth: '300px',
      data: { ...member, eventDate: this.event.eventDate },
      panelClass: 'dialog-modal',
      autoFocus: false,
    });
  }

  setExpandedMobilePanels(): void {
    this.event.members.forEach((member, idx) => {
      this.expandedMobileMembersPanel[idx] = false;
    });
  }

  toggleExpandedMobilePanels(idx: number): void {
    this.expandedMobileMembersPanel[idx] =
      !this.expandedMobileMembersPanel[idx];
  }
}
