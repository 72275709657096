import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { EVENT_MANAGER_ENVIRONMENT } from '../../tokens/event-manager-environment.token';
import { EcomEventDetailsComponent } from '../ecom-event-details/ecom-event-details.component';

@Component({
  selector: 'jfw-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements AfterViewInit {

  dealerPortal = inject(EVENT_MANAGER_ENVIRONMENT).dealerPortal;

  @ViewChild(EcomEventDetailsComponent) ecomEventDetailsComponent: EcomEventDetailsComponent | undefined;

  constructor() { }

  ngAfterViewInit() {
    // console.log('ecomEventDetailsComponent', this.ecomEventDetailsComponent);
  }
}
