import { Component, OnInit, inject } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import { Event, EventAdmin } from 'common-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transfer-confirmation',
  templateUrl: './transfer-confirmation.component.html',
  styleUrls: [
    './transfer-confirmation.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class TransferConfirmationComponent implements OnInit {
  event!: Event;
  subscription = new Subscription();
  transferredEvent = false;
  organizer!: EventAdmin;
  user!: User | null;

  private auth: Auth = inject(Auth);

  constructor(
    private eventService: EventService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      authState(this.auth).subscribe((user) => {
        if (user) {
          this.user = user;
          this.eventOrganizer();
        }
      }),
    );

    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          this.event = event;
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchFutureEvents(): void {
    let route = '/search';
    this.router.navigate([route], {
      queryParams: {
        searchType: 'future',
        eventDateFrom: Math.floor(new Date().getTime() / 1000),
        eventDateTo: Math.floor(
          new Date().setFullYear(new Date().getFullYear() + 2) / 1000,
        ),
      },
    });
  }

  eventOrganizer(): void {
    if (this.event && this.event.admins) {
      this.event.admins.forEach((admin) => {
        if (admin.id !== this.user?.uid) {
          this.organizer = admin;
          this.transferredEvent = true;
        }
      });
    }
  }
}
