import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { EventDetailsComponent } from '../../components/event-details/event-details.component';
import {
  ConfirmSaveEcomEventDetailsModalComponent,
  ConfirmSaveEcomEventDetailsModalData,
} from '../../components/modals/confirm-save-ecom-event-details/confirm-save-ecom-event-details';
import { EVENT_MANAGER_ENVIRONMENT } from '../../tokens/event-manager-environment.token';

export const ecomEventDetailsDeactivateGuard: CanDeactivateFn<
  EventDetailsComponent
> = async (
  component: EventDetailsComponent,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot,
): Promise<boolean> => {
  const environment = inject(EVENT_MANAGER_ENVIRONMENT);
  const dialog = inject(MatDialog);
  const isDealerPortal = environment.dealerPortal;
  if (isDealerPortal) {
    console.log('Dealer Portal environment.  Allow navigation.');
    return true;
  }

  const ecomEventDetailsComponent = component.ecomEventDetailsComponent;

  if (!ecomEventDetailsComponent) return true;

  const { form, event, isSaving } = ecomEventDetailsComponent;
  console.log('FORM', form);

  const newEventName = form.controls.eventName.value.trim();
  const existingEventName = event()?.eventName;
  const nameChanged = newEventName !== existingEventName;
  const newEventDate = form.controls.eventDate.value;
  const existingEventDate = event()?.eventDate;
  const eventDateChanged = newEventDate !== existingEventDate;
  if (isSaving()) {
    console.log('Prevent navigation while saving.');
    return false;
  }
  if (form.dirty) {
    console.log('Navigation started, but form is dirty.');
    if (nameChanged || eventDateChanged) {
      console.log(
        `${nameChanged ? 'eventName' : eventDateChanged ? 'eventDate' : 'something'}} has changed.  Prompt user to save changes.`,
      );
      ecomEventDetailsComponent.preventSave = true;
      try {
        const result = await firstValueFrom(
          dialog
            .open<
              ConfirmSaveEcomEventDetailsModalComponent,
              ConfirmSaveEcomEventDetailsModalData,
              { continueNoSave: boolean }
            >(ConfirmSaveEcomEventDetailsModalComponent, {
              data: {
                propChanged: nameChanged
                  ? 'eventName'
                  : eventDateChanged
                    ? 'eventDate'
                    : undefined,
              },
              maxWidth: '340px',
            })
            .afterClosed(),
        );

        if (result?.continueNoSave === true) {
          console.log('User does not want to save changes.  Allow navigation.');
          return true;
        }

        console.log('User wants to save changes.  Attempting to save changes.');
        ecomEventDetailsComponent.preventSave = false;
        form.controls.eventName.setValue(newEventName);
        return false;
      } catch (error) {
        console.error('Error handling dialog: ', error);
        console.log('Resetting details form.');
        ecomEventDetailsComponent.initializeDetailsForm(event());
        ecomEventDetailsComponent.preventSave = false;
        return false;
      }
    }

    console.log('Event name has not changed.  Allow navigation.');
    return true;
  }

  console.log('Navigation started, and form is not dirty.  Allow navigation.');
  // If the form is not dirty, allow navigation
  return true;
};
