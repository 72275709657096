import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { EventService } from '@jfw-library/ecommerce/core';
import { ZodFormUtilities } from '@jfw-library/ecommerce/zod-forms';
import {
  JfwEmailValidator,
  PhoneFormComponent,
} from '@jfw-library/shared/common-components/form-fields';
import {
  generateUUID,
  getAvailableEventRoles,
  isPaidForMember,
} from 'business-logic';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  Event,
  EventMember,
  EventRole,
  JFWNewEmailSchema,
} from 'common-types';
import {
  ObservedValueOf,
  Subscription,
  firstValueFrom,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { EventManagerService } from '../../services/event-manager-service';
import { ConfirmDeleteMemberModalComponent } from '../modals/confirm-delete-member/confirm-delete-member.component';

interface MemberForm {
  id: FormControl<string | null>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string | null>;
  role: FormControl<string | null>;
  phone?: ObservedValueOf<PhoneFormComponent['formReady']>;
  thisIsMe: FormControl<boolean>;
}

@Component({
  selector: 'app-add-members',
  templateUrl: './add-members.component.html',
  styleUrls: [
    './add-members.component.scss',
    '../../event-manager.component.scss',
  ],
})
export class AddMembersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('formDirective') formDirective!: FormGroupDirective;
  @ViewChild('expansionPanel') expansionPanel!: MatExpansionPanel;
  @ViewChildren('measselect')
  components!: QueryList<MatSelect>;

  event!: Event;
  editIndex = -1;
  subscription = new Subscription();
  expansionPanelOpen = false;
  phone = '';
  validatorMsg = '';
  showWarning$ = this.eventManagerService.showWarning$;
  isMobile = false;
  invalidMembers: Array<number> | undefined = [];
  dealerPortal = this.environment.dealerPortal;
  displayThisIsMe: boolean = false;
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  /**
   * Used to display the loading spinner when adding, editing, or deleting a member.
   * 'add' - set to 'add' when adding a new member
   * 'edit' - set to 'edit' when editing an existing member
   * number - set to the index of the member being deleted when deleting a member
   * null - set to null when saving is finished or not in progress
   */
  saving: 'add' | 'edit' | number | null = null;

  form = new FormGroup<MemberForm>({
    id: new FormControl(null),
    firstName: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    lastName: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    email: new FormControl('', {
      // validators: JfwEmailValidator.isValid(),
      validators: [
        Validators.required,
        ZodFormUtilities.zodFormControlValidator(JFWNewEmailSchema),
      ],
      nonNullable: true,
    }),
    role: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    thisIsMe: new FormControl(false, { nonNullable: true }),
  });

  private document: Document = inject(DOCUMENT);

  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    private matDialog: MatDialog,
    private eventManagerService: EventManagerService,
    private eventService: EventService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  getFirstError = ZodFormUtilities.getFirstOfAllFieldErrorsZodFirst;

  ngOnInit(): void {
    this.initializeForms();
    this.subscription.add(
      this.eventService.selectedEvent$.subscribe({
        next: (event) => {
          this.event = event;

          /// This will validate current members for any missing data
          /// Keeping this code commented out for now.  This was being used to show error on initial load, but Marketing does not want that.
          // // if (!this.dealerPortal) {
          // //   this.validateCurrentMembers();
          // // }
        },
      }),
    );

    this.subscription.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe({
        next: (result) => (this.isMobile = result.matches),
      }),
    );

    // this.subscription.add(
    //   this.eventManagerService.results$.subscribe((validator) => {
    //     console.log('results$ validator:', validator);
    //     if (validator) {
    //       this.validatorMsg = validator?.msg;
    //     }
    //   })
    // );

    this.subscription.add(
      this.eventManagerService.results$.subscribe((validator) => {
        if (validator) {
          // console.log('results$ validator:', validator);
          this.validatorMsg = validator.msg;
          this.invalidMembers = validator.invalidMembers;
          // this.cdr.detectChanges();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    // console.log('add-members component destroyed');
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {}

  /// Keeping this code commented out for now.  This was being used to show error on initial load, but Marketing does not want that.
  // // validateCurrentMembers() {
  // //   // console.log('validating current members');
  // //   const initialValidation = this.validationService.isEventStepValid(
  // //     this.event,
  // //     'add-members'
  // //   );
  // //   if (!initialValidation.valid) {
  // //     this.validatorMsg = initialValidation?.msg;
  // //     this.invalidMembers = initialValidation?.invalidMembers;
  // //     /// Why do I need to use a setTimeout here?
  // //     // setTimeout(() => {
  // //     //   this.eventManagerService.nextClicked();
  // //     // });
  // //   } else {
  // //     this.validatorMsg = '';
  // //     this.invalidMembers = [];
  // //   }
  // // }

  private initializeForms(): void {
    if (this.dealerPortal) {
      this.form = new FormGroup<MemberForm>({
        id: new FormControl(null),
        firstName: new FormControl('', { nonNullable: true }),
        lastName: new FormControl('', { nonNullable: true }),
        email: new FormControl('', {
          validators: [JfwEmailValidator.isValid()],
        }),
        role: new FormControl(''),
        thisIsMe: new FormControl(false, { nonNullable: true }),
      });
    }
  }
  public isAddMemberValid(index: number): boolean {
    if (this.invalidMembers?.includes(index)) {
      return false;
    } else {
      return true;
    }
  }

  public setPhone(phone: string | undefined) {
    if (phone !== undefined) {
      this.phone = phone;
    }
  }

  public addChildForm<key extends keyof MemberForm>(
    name: key,
    group: Exclude<MemberForm[key], undefined>,
  ) {
    this.form.setControl(name, group);
  }

  public openExpansionPanel(): void {
    let member: EventMember | undefined;
    if (this.editIndex !== -1) {
      member = this.event.members[this.editIndex];
    }

    if (this.disableThisIsMeCheckbox(member)) {
      this.form.controls.thisIsMe.disable();
    } else {
      this.form.controls.thisIsMe.enable();
    }

    this.expansionPanelOpen = true;
  }

  public closeExpansionPanel(): void {
    this.expansionPanelOpen = false;
    this.document.body.scrollTop = this.document.documentElement.scrollTop = 0;
  }

  public getRoles(): EventRole[] {
    return getAvailableEventRoles(this.event);
  }

  private getNewMember(): EventMember {
    return {
      id: generateUUID(this.isBrowser),
      firstName: '',
      lastName: '',
      memberRole: '',
      phone: '',
      email: '',
      surrogateUser: false,
      measurements: {},
      shippingAddress: {
        name: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
        shippingMethod: '',
        phone: '',
      },
    };
  }

  public isPaidFor(member: EventMember): boolean {
    return isPaidForMember(member);
  }

  public hasPaidMember(): boolean {
    let paid = false;
    this.event.members.forEach((member) => {
      if (this.isPaidFor(member)) {
        paid = true;
      }
    });
    return paid;
  }

  private thisMemberIsMe(member: EventMember): boolean {
    return this.eventManagerService.thisMemberIsMe(member);
  }

  private oneOfTheMembersIsMe(): boolean {
    let result = false;

    for (let member of this.event.members) {
      if (this.thisMemberIsMe(member)) {
        result = true;
      }
    }

    return result;
  }

  private disableThisIsMeCheckbox(member?: EventMember): boolean {
    if (member) {
      if (this.thisMemberIsMe(member)) {
        return false;
      }
    }

    return this.oneOfTheMembersIsMe();
  }

  /** clears the form when expansion panel is closed */
  public resetForm(): void {
    this.expansionPanelOpen = false;
    this.formDirective.resetForm();
    this.form.reset();
    this.editIndex = -1;
    if (this.dealerPortal) {
      const previousPhone = this.form.controls.phone;
      this.form = new FormGroup<MemberForm>({
        id: new FormControl(null),
        firstName: new FormControl('', { nonNullable: true }),
        lastName: new FormControl('', { nonNullable: true }),
        email: new FormControl('', {
          validators: [JfwEmailValidator.isValid()],
        }),
        phone: previousPhone,
        role: new FormControl(''),
        thisIsMe: new FormControl(false, { nonNullable: true }),
      });
    }
  }

  /**
   * Either adds a new member to the event or updates an existing member with the form data.
   * @returns
   */
  public async onSave(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    console.log('saving member...');

    const isNewMember = this.editIndex === -1;
    const member: EventMember = isNewMember
      ? this.getNewMember()
      : { ...this.event.members[this.editIndex] }; // make copy of member to avoid mutating the original member (in case save fails we don't need to reset the form)

    if (member?.id === undefined) {
      this.saving = null;
      throw new Error('Member does not have an id');
    }

    member.firstName = this.form.controls.firstName.value;
    member.lastName = this.form.controls.lastName.value;
    member.phone = this.phone;
    member.email = this.form.controls.email.value ?? '';
    member.memberRole = this.form.controls.role.value ?? '';

    // const memberIsCurrentUser = this.form.controls.thisIsMe.value;

    try {
      this.saving = isNewMember ? 'add' : 'edit';
      const saveResult = isNewMember
        ? await firstValueFrom(
            this.eventManagerService.addMember(this.event.id, member),
          )
        : await firstValueFrom(
            this.eventManagerService.updateMemberDetails(
              this.event.id,
              member.id,
              {
                firstName: member.firstName,
                lastName: member.lastName,
                memberRole: member.memberRole,
                phone: member.phone,
                email: member.email,
              },
            ),
          );

      console.log('save result: ', saveResult);
      const { updatedEvent } = saveResult;

      this.eventService.setSelectedEventWithEvent(
        updatedEvent,
        'AddMembersComponent - onSave',
      );

      this.resetForm();
      // this.validate(updatedEvent); //TODO: This might need to be re-worked to handle new event flow since we are not mutating the event object directly

      this.saving = null;
      console.log('member saved');
    } catch (error) {
      console.error('onSave error:', error);
      alert('There was an error saving the member');
      this.saving = null;
    }

    // const result = await firstValueFrom(iif(() => isNewMember,
    //   (() => { // if new member, add member to event
    //     this.saving = 'edit';
    //     return this.eventManagerService.addMember(this.event.id, member)
    //   })(),
    //   (() => { // else update member details
    //     this.saving = 'add';
    //     const updatedDetails = {
    //       firstName: member.firstName,
    //       lastName: member.lastName,
    //       memberRole: member.memberRole,
    //       phone: member.phone,
    //       email: member.email,
    //     }
    //     return this.eventManagerService.updateMemberDetails(this.event.id, member.id, updatedDetails)
    //   })()
    // ).pipe(
    //   tap((result) => {
    //     console.log('onSave result: ', result);
    //   }),
    //   switchMap(result => this.eventService.setSelectedEventWithEvent(result.updatedEvent)), // get the event from db
    //   tap((event) => console.log("onSave getSelectedEvent event received: ", event)),
    //   catchError((error) => {
    //     console.error("onSave error:", error);
    //     alert('There was an error saving the member');
    //     return of(null);
    //   })
    // ));

    // if (result !== null) {
    //   this.resetForm();
    //   this.validate(result); //TODO: This might need to be re-worked to handle new event flow since we are not mutating the event object directly
    // }

    // this.saving = null;
    // console.log("member saved");

    // this.eventService.updateEventMembers(
    //   member,
    //   this.editIndex,
    //   memberIsCurrentUser
    // );

    // this.resetForm();
    // this.validate();
  }

  /**
   * Called when the user clicks the edit button for a member.
   * Initializes the form with the selected member's data or with empty fields if adding a new member.
   * Then opens the expansion panel, scrolls to the top of the page, and marks the form as touched if the form is invalid.
   * @param member selected member to edit
   * @param index index of the member in the event.members array
   */
  public onEdit(member: EventMember, index: number): void {
    this.editIndex = index;
    if (this.event.members[index].shippingAddress === undefined) {
      this.event.members[index].shippingAddress = {
        name: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
        shippingMethod: '',
        phone: '',
      };
    }

    member.firstName = member.firstName ?? '';
    member.lastName = member.lastName ?? '';
    member.memberRole = member.memberRole ?? '';
    member.phone = member.phone ?? '';
    member.email = member.email ?? '';
    this.form.setValue({
      id: member.id!,
      firstName: member.firstName,
      lastName: member.lastName,
      role: member.memberRole,
      phone: { phone: member.phone },
      email: member.email,
      thisIsMe: this.eventManagerService.thisMemberIsMe(member),
    });

    this.openExpansionPanel();
    if (!this.isAddMemberValid(index)) {
      this.form.markAllAsTouched();
    }
    this.scrollToTop();
  }

  public async onDelete(member: EventMember): Promise<Event | null> {
    // if (
    //   window.confirm(
    //     `Are you sure you want to remove ${member.firstName} ${member.lastName} from this event?`
    //   )
    // ) {
    //   this.deleteMember(member);
    // }

    let dialog = this.matDialog.open(ConfirmDeleteMemberModalComponent, {
      data: member,
      maxWidth: '350px',
    });
    return firstValueFrom(
      dialog.afterClosed().pipe(
        switchMap((response) => {
          if (response.deleteMember) {
            console.log(
              'delete member confirmed.  Proceeding to delete member...',
            );
            return this.deleteMember(member, this.event.id);
          } else return of(null);
        }),
        tap((result) => {
          console.log('onDelete result: ', result);
        }),
      ),
    );
  }

  private async deleteMember(
    memberToDelete: EventMember,
    eventId: string,
  ): Promise<Event | null> {
    const memberId = memberToDelete.id;
    if (!memberId) throw new Error('Member does not have an id');

    this.saving = this.event.members.findIndex(
      (member) => member.id === memberId,
    );

    try {
      const deleteMemberResult = await firstValueFrom(
        this.eventManagerService.deleteMember(eventId, memberId),
      );
      console.log('deleteMember result: ', deleteMemberResult);
      const { updatedEvent } = deleteMemberResult;

      this.eventService.setSelectedEventWithEvent(
        deleteMemberResult.updatedEvent,
        'AddMembersComponent - deleteMember',
      );

      // this.validate(updatedEvent);

      this.saving = null;

      return updatedEvent;
    } catch (error) {
      console.error('deleteMember error:', error);
      alert('There was an error deleting the member');
      this.saving = null;
      return null;
    }

    // const result = this.eventManagerService.deleteMember(eventId, memberId).pipe( // delete member from event
    //   tap((result) => console.log('deleteMember result: ', result)),
    //   switchMap(result => this.eventManagerService.deleteUserEventByMemberIdEventId( // then delete user event
    //     memberId,
    //     eventId
    //   )),
    //   tap((result) => console.log('apiDeleteUserEventByMemberIdEventId result: ', result)),
    //   map(result => 'successfully deleted member from event and user event'),
    //   switchMap(result => this.eventService.getSelectedEvent(true)), // then get the event from db
    //   tap((event) => {

    //     this.validate(event);
    //     this.saving = null;
    //   }),
    //   catchError((error) => {
    //     console.error("deleteMember error:", error);
    //     alert('There was an error deleting the member');
    //     this.saving = null;
    //     return of(null);
    //   })
    // );

    // return result;

    // this.event.members = this.event.members.filter(
    //   (savedMember) => savedMember.id !== deletedMember.id
    // );

    // this.eventService.deleteAdminFromMember(deletedMember, this.event);

    // this.validate();
    // if (this.event.memberIds?.length) {
    //   this.event.memberIds = this.event.memberIds.filter(
    //     (id) => id !== memberId
    //   );
    // }
    // this.eventService.updateEvent(this.event);

    // this.eventApiService.apiDeleteUserEventByMemberIdEventId(
    //   memberId,
    //   this.event.id
    // );
  }

  private scrollToTop(): void {
    let header = this.document.getElementsByClassName('grid')[0];
    setTimeout(() => {
      header.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }

  roleFieldFocusById(item: string) {
    const htmlElement = this.document.getElementById(item);
    if (htmlElement) {
      htmlElement.focus();
      const matSelectQueryList = this.components.toArray();
      for (let i = 0; i < matSelectQueryList.length; i++) {
        if ((<MatSelect>matSelectQueryList[i]).id === item) {
          (<MatSelect>matSelectQueryList[i]).open();
          break;
        }
      }
    }
  }
}
